import React from 'react';
import { EntranceScreen } from '../../components/core/EntranceScreen/EntranceScreen';
import { Header } from '../../components/core/Layout/Header/Header';
import YannickDrawing from '../../assets/images/Headers/TeamCoding.jpeg';
import SamuelTyping from '../../assets/images/SectionImages/coding2.jpeg';
import { SectionTitle } from '../../components/SectionTitle/SectionTitle';
import { ImageIconRepresentation } from '../../components/ImageIconRepresentation/ImageIconRepresentation';
import IconTextRepresentation from '../../components/IconTextRepresentation/IconTextRepresentation';
import {
    faBrowser,
    faChartPie,
    faDraftingCompass,
    faGlobe,
    faRocketLaunch,
    faServer,
} from '@fortawesome/pro-light-svg-icons';
import ContactSection from '../../components/ContactSection/ContactSection';
import GoogleMap from '../../components/GoogleMap';
import Footer from '../../components/core/Footer';

export const BackgroundServices = () => {
    return (
        <>
            <Header />
            <EntranceScreen
                title="Background-Services bei InterMeida"
                subtitle="Du hast ein Problem? Wir haben die Lösung."
                image={YannickDrawing}
            />
            <SectionTitle
                category="Background-Services"
                title="Um einen vollumfänglichen Service anbieten zu können, übernehmen wir alle aufgaben Rund um die App Entwicklung"
            />
            <ImageIconRepresentation image={SamuelTyping}>
                <IconTextRepresentation
                    icon={[faDraftingCompass]}
                    title="Logo Design"
                    text="Geben Sie Ihrer Marke ein aussagekräftiges Auftreten mit einem einprägsamen Logo."
                />
                <IconTextRepresentation
                    icon={[faServer]}
                    title="Hosting"
                    text="Wir übernehmen die gesamte Hostingabwicklung des Backend-Services, redundant auf Hochleistungsservern in der Cloud und Lokal."
                />
                <IconTextRepresentation
                    icon={[faBrowser]}
                    title="Webapps"
                    text="Machen Sie Ihre App auch für Desktop-Nutzer verfügbar."
                />
                <IconTextRepresentation
                    icon={[faChartPie]}
                    title="Verwaltungsdashboard"
                    text="Neben Live-Kennzahlen können Sie hier Einstellungen oder Verwaltungsaufgaben Ihrer App vornehmen."
                />
                <IconTextRepresentation
                    icon={[faGlobe]}
                    title="Landingpages"
                    text="Eine App lebt von Ihren Kunden. Rücken Sie Ihr Produkt mit einer Landingpage in das richtige Licht und gewinnen systematisch Neukunden."
                />
                <IconTextRepresentation
                    icon={[faRocketLaunch]}
                    title="Release-Management"
                    text="Der Release in den App-Store ist meistens sehr komplex. Profitieren Sie von unserer Erfahrung und ersparen Sie sich den Stress."
                />
            </ImageIconRepresentation>
            <SectionTitle category="Werde Kunde" title="Kontaktiere uns" />
            <ContactSection />
            <GoogleMap />
            <Footer />
        </>
    );
};
