interface ICategoryCardProps {
  title: string;
  link?: string;
  image?: string;
}

export const CategoryCard = (props: ICategoryCardProps) => {
  const { title, link, image } = props;
  return (
    <div className="w-full sm:w-52 md:w-64 border rounded-2xl my-10 overflow-hidden">
      <img
        className="aspect-[3/2] w-full rounded-t-2xl object-cover hover:scale-105 overflow-hidden transition-all hover:-translate-y-1"
        src={
          image
            ? image
            : "https://images.unsplash.com/photo-1521737711867-e3b97375f902?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1587&q=80"
        }
        alt=""
      />
      <div className="p-4">
        <div className="text-2xl font-bold leading-8 text-gray-900 mb-10">
          {title}
        </div>
        <a
          href="/Service"
          className="text-sm font-semibold leading-6 text-gray-900"
        >
          Mehr erfahren <span aria-hidden="true">→</span>
        </a>
      </div>
    </div>
  );
};
