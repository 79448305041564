import React, { useEffect } from "react";
import { faApple, faWindows } from "@fortawesome/free-brands-svg-icons";
import {
  faBriefcase,
  faBuilding,
  faClock,
  faCommentDots,
  faHandshakeAlt,
  faHome,
  faUserFriends,
  faUsers,
} from "@fortawesome/pro-light-svg-icons";

import Button from "../../components/core/Button";
import ContactSection from "../../components/ContactSection/ContactSection";
import EmreWorking from "../../assets/images/SectionImages/EmreWorking.jpeg";
import { EntranceScreen } from "../../components/core/EntranceScreen/EntranceScreen";
import Footer from "../../components/core/Footer";
import GoogleMap from "../../components/GoogleMap";
import { Header } from "../../components/core/Layout/Header/Header";
import { Helmet } from "react-helmet";
import IconTextRepresentation from "../../components/IconTextRepresentation/IconTextRepresentation";
import { ImageIconRepresentation } from "../../components/ImageIconRepresentation/ImageIconRepresentation";
import { JobCard } from "../../components/JobCard/JobCard";
import JobCardWrapper from "./JobCardWrapper/JobCardWrapper";
import SamuelTyping from "../../assets/images/Headers/SamuelTyping.jpeg";
import SaschaSide from "../../assets/images/SectionImages/SaschaSide.jpeg";
import { SectionTitle } from "../../components/SectionTitle/SectionTitle";
import { SmallTitle } from "../../components/SmallTitle/SmallTitle";
import { useLocation } from "react-router-dom";

//import SaschaSide from '../../assets/images/SectionImages/SaschaSide.jpg';

export const Karriere = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <Helmet>
        <title>Karriere bei InterMedia</title>
        <meta
          name="description"
          content="Als junges Unternehmen setzen wir auf ein angenehmes Arbeitsumfeld für dich!"
        />
      </Helmet>
      <Header />
      <EntranceScreen
        title="Karriere bei InterMedia"
        subtitle="werde Teil eines fantastischen Teams"
        image={SamuelTyping}
        altText={
          "Ein Entwickler beim Programmieren einer App für ein Smartphone"
        }
      />
      <SectionTitle
        category="Werde Teil unseres Teams"
        title="Als junges Unternehmen setzen wir auf ein angenehmes Arbeitsumfeld für dich!"
        needHelpText="Haben wir dein Interesse geweckt?"
      />
      <ImageIconRepresentation
        image={EmreWorking}
        imageDescription="Ein Programmierer beim Entwickeln einer Anwendung"
      >
        <IconTextRepresentation
          icon={[faApple, faWindows]}
          title="Freie Gerätewahl"
          text="Bei uns kannst du mit dem Gerät arbeiten, mit dem du dich am wohlsten fühlst."
        />
        <IconTextRepresentation
          icon={[faUsers]}
          title="Junges Team"
          text="Unser Durchschnittsalter liegt bei 22 Jahren."
        />
        <IconTextRepresentation
          icon={[faHome]}
          title="Homeoffice"
          text="Die Kleinen müssen in den Kindergarten? Du hast einen Arzttermin? Kein Problem. Du kannst entspannt von Zuhause aus arbeiten."
        />
        <IconTextRepresentation
          icon={[faClock]}
          title="Flexible Arbeitszeiten"
          text="Du entscheidest, wann du kommst und wann du gehst."
        />
        <IconTextRepresentation
          icon={[faCommentDots]}
          title="Vorschläge"
          text="Du hast etwas gefunden, was uns Zeit und Stress erspart? Bringe eigene Ideen und Vorschläge mit ein und erhalte einen Bonus!"
        />
        <IconTextRepresentation
          icon={[faBuilding]}
          title="Modernes Office"
          text="Unser neues Office bietet genug Platz und moderne Ausstattung, um dir den Arbeitsalltag so angegehm wie möglich zu gestalten."
        />
      </ImageIconRepresentation>
      <SectionTitle
        category="Werde Teil unseres Teams"
        title="Ausgeschriebene Stellen"
        needHelpText="Haben wir dein Interesse geweckt?"
      />
      <JobCardWrapper>
        <JobCard
          jobTitle="Fachinformatiker-/in"
          jobCategory="Backendprogrammierung"
          description="Du liebst es ausgefallene Logiken zu programmieren? Dann ist dieser Job genau das Richtige für
                        dich. Werde Teil unseres Teams als Backend-Entwickler und realisiere spannende Projekte mit PHP
                        und .Net Core."
        />
        <JobCard
          jobTitle="Mediengestalter-/in"
          jobCategory="UI & UX Design"
          description="Du liebst es innovative Konzepte zu erstellen? Dann ist dieser Job genau das Richtige für
                        dich. Werde Teil unseres Teams als UI & UX Designer."
        />
      </JobCardWrapper>
      <SmallTitle
        title="Nicht fündig geworden?"
        subtitle="Kein Problem! Wir sind ständig auf der Suche nach versteckten Talenten. Schreibe uns einfach eine Initiativbewerbung."
      />
      <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <a href="mailto:hey@inter-media.io">
          <Button title="Initiativbewerbung" />
        </a>
      </div>
      <SmallTitle title="Was wir suchen" subtitle="Unser Anspruch an dich." />
      <ImageIconRepresentation
        image={SaschaSide}
        ImageSize="big"
        imageDescription="Experten bei einem Meeten für ein Softwareprojekt"
        containerStyle={{ justifyContent: "space-between" }}
      >
        <IconTextRepresentation
          icon={[faHandshakeAlt]}
          title="Commitment"
          text="Wir suchen Mitarbeiter, die unser Unternehmen zu 100% nach außen vertreten."
          fullwidth
        />
        <IconTextRepresentation
          icon={[faUserFriends]}
          title="Langfristige Arbeitsverhältnisse"
          text="Wir wollen dich nicht nach einem Jahr wieder gehen lassen. Komm in unser Team und werde mit uns der Beste deines Fachs."
          fullwidth
        />
        <IconTextRepresentation
          icon={[faBriefcase]}
          title="Engagement"
          text="Zeig uns, was du bereits gemacht hast. Egal ob in deinem Job oder als ein privates Projekt!"
          fullwidth
        />
      </ImageIconRepresentation>
      <div style={{ display: "flex", justifyContent: "center", flex: 1 }}>
        <a href="mailto:hey@inter-media.io">
          <Button title="Jetzt Bewerben" />
        </a>
      </div>
      <ContactSection />
      <Footer />
    </>
  );
};
