import { Link, useLocation } from "react-router-dom";
import {
  faCheckDouble,
  faClock,
  faHandshakeAlt,
  faUserFriends,
} from "@fortawesome/pro-light-svg-icons";

import { Benefit } from "../../components/Benefit";
import Button from "../../components/core/Button";
import { CategoryCard } from "../../components/CategoryCard/CategoryCard";
import ContactSection from "../../components/ContactSection/ContactSection";
import Footer from "../../components/core/Footer";
import GoogleMap from "../../components/GoogleMap";
import { Header } from "../../components/core/Layout/Header/Header";
import { Helmet } from "react-helmet";
import IconTextRepresentation from "../../components/IconTextRepresentation/IconTextRepresentation";
import { ImageIconRepresentation } from "../../components/ImageIconRepresentation/ImageIconRepresentation";
import ImgConsulting from "../../assets/images/consulting2.jpg";
import ImgDevelopment from "../../assets/images/development.jpg";
import ImgEngeneering from "../../assets/images/engeneering.jpg";
import { SectionTitle } from "../../components/SectionTitle/SectionTitle";
import coding from "../../assets/images/SectionImages/coding2.jpeg";
import { useEffect } from "react";

export const Home = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <Helmet>
        <title>InterMedia - Professionelle App-Entwicklung</title>
        <meta
          name="description"
          content="Wir sind Experten in der Entwicklung von individuellen Lösungen als Webanwendung im Browser oder als App auf dem Smartphone."
        />
      </Helmet>
      <Header />
      <div className="bg-white mt-10">
        <div className="relative">
          <div className="mx-auto max-w-screen-xl">
            <div className="relative z-10 pt-14 lg:w-full lg:max-w-2xl">
              <svg
                className="absolute inset-y-0 right-8 hidden h-full w-80 translate-x-1/2 transform fill-white lg:block"
                viewBox="0 0 100 100"
                preserveAspectRatio="none"
                aria-hidden="true"
              >
                <polygon points="0,0 90,0 50,100 0,100" />
              </svg>

              <div className="relative px-6 py-32 sm:py-40 lg:px-8 lg:py-56 lg:pr-0">
                <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-xl">
                  <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
                    Das beste Team für Individuelle Softwareprojekte
                  </h1>
                  <p className="mt-6 text-lg leading-8 text-gray-600">
                    Schaffe mit einer Individuellen Software deinen
                    Wettbewerbsvorteil.
                  </p>
                  <div className="mt-10 flex items-center gap-x-6">
                    <a
                      href="/Anfrage"
                      className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                      Kostenloses Erstgespräch
                    </a>
                    <a
                      href="/Service"
                      className="text-sm font-semibold leading-6 text-gray-900"
                    >
                      Mehr erfahren <span aria-hidden="true">→</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-gray-50 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
            <img
              className="aspect-[3/2] object-cover lg:aspect-auto lg:h-full lg:w-full"
              src="https://images.unsplash.com/photo-1521737711867-e3b97375f902?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1587&q=80"
              alt=""
            />
          </div>
        </div>
      </div>
      <div className="flex flex-col px-8 lg:flex-row max-w-screen-xl mx-auto lg:mt-28">
        <div className="mt-20 lg:mr-10 md:max-w-xl">
          <p className="mt-6 text-4xl font-bold">
            Unsere Lösungen für Ihr Unternehmen
          </p>
          <p className="mt-6 text-lg">
            Wir haben uns im Bereich der Softwareentwicklung vor allem auf
            individuelle Gesamtlösungen unter Benutzung modernster Technologien
            und Praktiken spezialisiert:
          </p>
          <div className="mt-10">
            <Benefit title="Beratung & Konzeption von Softwareprojekten" />
            <Benefit title="Entwicklung und Wartung von Software" />
            <Benefit title="Migration von bestehender Software" />
            <Benefit title="Implementierung auf eine Cloud-Infrastruktur" />
            <Benefit title="Digitalisierung und Automatisierung von Prozessen" />
          </div>
          <div className="mt-8">
            <a
              type="button"
              href="/Anfrage"
              className="rounded-full bg-indigo-600 px-4 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Kostenloses Erstgespräch vereinbaren
            </a>
          </div>
        </div>
        <div className="flex flex-col mx-auto sm:flex-row">
          <div className="lg:mt-10 mx-5">
            <CategoryCard
              title={"Beratung & Konzeption"}
              image={ImgConsulting}
            />
            <CategoryCard title={"Cloud Engineering"} image={ImgEngeneering} />
          </div>
          <div className="mx-5">
            <CategoryCard
              title={"Entwicklung von Software"}
              image={ImgDevelopment}
            />
            <CategoryCard title={"UI / UX Design"} />
          </div>
        </div>
      </div>

      <SectionTitle
        title="Über InterMedia - Ihr Experte für die App Entwicklung"
        category="About"
      />
      <ImageIconRepresentation
        image={coding}
        imageDescription="Ein Experte beim programmieren einer App."
      >
        <IconTextRepresentation
          icon={[faHandshakeAlt]}
          title="Commitment"
          text="Unser Team liebt es, sich in spannende und fordernde Aufträge zu stürzen. „Das Undenkbare zu schaffen ist immer noch das Beste am Job hier bei InterMedia“"
        />
        <IconTextRepresentation
          icon={[faUserFriends]}
          title="Langfristige Kundenbeziehungen"
          text="Wir wollen unsere Kunden lange zufriedenstellen. Mit einer persönlichen und langjährigen Zusammenarbeit können wir Großes schaffen."
        />
        <IconTextRepresentation
          icon={[faCheckDouble]}
          title="Kompetenz und Sicherheit"
          text="Wir bieten dir die Sicherheit einer ausgezeichnet funktionierenden App. Wir bieten dir das gute Gefühl, deine App in den App-Stores zu sehen. Wir geben dir die Sicherheit, Fehler schnell zu beheben."
        />
        <IconTextRepresentation
          icon={[faClock]}
          title="Nachhaltigkeit"
          text="Anwendungen sollten nicht nur die Anforderungen der Gegenwart erfüllen, sondern auch das Künftige. Wir schaffen eine App, die mit deinen Anforderungen wachsen kann."
        />
      </ImageIconRepresentation>
      <ContactSection />
      <Footer />
    </>
  );
};
