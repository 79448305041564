import "./SectionTitle.style.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Layout from "../core/Layout";
import { Link } from "react-router-dom";
import React from "react";
import { faChevronRight } from "@fortawesome/pro-light-svg-icons";

interface ISectionTitleProps {
  category: string;
  title: string;
  needHelpText?: string;
  letsTalkLink?: string;
  light?: boolean;
}

export const SectionTitle = (props: ISectionTitleProps) => {
  const {
    category = "Lösungen",
    title = "Titel",
    needHelpText = "Sie benötigen eine individuelle Lösung?",
    light = false,
  } = props;
  return (
    <Layout>
      <div className="root max-w-screen-xl mx-auto">
        <div>
          <div className={`category ${light && "lightText"}`}>{category}</div>
        </div>
        <div className="content">
          <div className="titleContainer">
            <div className={`title ${light && "lightText"}`}>{title}</div>
          </div>
          <div className={"secondaryText"}>
            <div className={`needHelp ${light && "lightText"}`}>
              {needHelpText}
            </div>
            <div className={`letsTalk ${light && "lightText"}`}>
              <Link to="/Anfrage">
                <div
                  style={{ display: "flex", alignItems: "center" }}
                  className="letsTalkAnimation"
                >
                  Let's talk
                  <FontAwesomeIcon
                    icon={faChevronRight}
                    size={"xs"}
                    color={light ? "lightText" : "#262CCF"}
                    style={{ marginLeft: "8px" }}
                    className="letsTalkAnimationIcon"
                  />
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};
