import React from "react";

export const GoogleMap = () => {
  const containerStyle = {
    width: "100%",
    height: "300px",
  };

  const center = {
    lat: 50.00376,
    lng: 12.09368,
  };

  return (
    <div>
      <iframe
        width="600"
        height="500"
        id="gmap_canvas"
        src="https://www.google.com/maps/embed/v1/place?q=Heilbronner%20Str.%20150%2C%20Stuttgart%2C%20Nord%2C%20Deutschland&key=AIzaSyBZOGUvYRgk0XYQrAfxiAv887ieHqqTJ50"
        frameBorder="0"
        scrolling="no"
        marginHeight={0}
        marginWidth={0}
        style={containerStyle}
        title="InterMedia GmbH Google Maps"
      ></iframe>
    </div>
    // <LoadScript googleMapsApiKey="AIzaSyCcrzWYblLF0BQP0skGHBtOMrrNS6ZT-ao">
    //     <GMap
    //         options={{
    //             draggable: false,
    //             zoomControl: false,
    //             fullscreenControl: false,
    //         }}
    //         mapContainerStyle={containerStyle}
    //         center={center}
    //         zoom={15}
    //     >
    //         <Marker position={center} />
    //     </GMap>
    // </LoadScript>
  );
};

export default GoogleMap;
