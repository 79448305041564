import { Link, useLocation } from "react-router-dom";
import React, { useEffect } from "react";
import {
  faCode,
  faCogs,
  faEdit,
  faPencilPaintbrush,
  faRocketLaunch,
} from "@fortawesome/pro-light-svg-icons";

import { Button } from "../../components/core/Button/Button";
import Coding from "../../assets/images/Timeline/AppDevelopment/Coding.png";
import ContactSection from "../../components/ContactSection/ContactSection";
import Design from "../../assets/images/Timeline/AppDevelopment/Design.png";
import EmreCoding from "../../assets/images/Headers/TeamCoding.jpeg";
import { EntranceScreen } from "../../components/core/EntranceScreen/EntranceScreen";
import Footer from "../../components/core/Footer";
import GoogleMap from "../../components/GoogleMap";
import { Header } from "../../components/core/Layout/Header/Header";
import { Helmet } from "react-helmet";
import IconTextRepresentation from "../../components/IconTextRepresentation/IconTextRepresentation";
import { ImageIconRepresentation } from "../../components/ImageIconRepresentation/ImageIconRepresentation";
import Planning from "../../assets/images/Timeline/AppDevelopment/Planning.png";
import Release from "../../assets/images/Timeline/AppDevelopment/Release.png";
import SamuelCoding from "../../assets/images/SectionImages/coding.jpeg";
import { SectionTitle } from "../../components/SectionTitle/SectionTitle";
import TimelineTracker from "../../components/TimelineTracker/TimelineTracker";
import { faReact } from "@fortawesome/free-brands-svg-icons";

export const AppDevelopment = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <>
      <div>
        <Header />
      </div>
      <Helmet>
        <title>App-Entwicklung</title>
        <meta
          name="description"
          content="Mit unserer Kompetenz & Expertise in der App Entwicklung ist InterMedia der ideale Ansprechpartner für dein Projekt."
        />
      </Helmet>
      <EntranceScreen
        title="App-Development bei InterMedia"
        subtitle="Von der Idee in den Store"
        altText="Ein Entwicklerteam während des Programmierens einer App"
        image={EmreCoding}
      />
      <SectionTitle
        category="App Entwicklung"
        title="Mit unserer Kompetenz & Expertise in der App Entwicklung ist InterMedia der ideale Ansprechpartner für dein Projekt."
      />
      <ImageIconRepresentation
        image={SamuelCoding}
        imageDescription="App-Entwickler beim programmieren einer App für das Smartphone"
      >
        <IconTextRepresentation
          icon={[faPencilPaintbrush]}
          title="UI & UX Design"
          text="Wir wissen, das Wichtigste an einer Software ist das Nutzererlebnis. Deshalb bauen wir unsere Produkte um dieses Erlebnis herum."
        />
        <IconTextRepresentation
          icon={[faEdit]}
          title="Konzeption"
          text="Die besten Apps der Geschichte waren auch mal nur eine Idee. Wir nehmen deine Idee und konzipieren mit dir die Straße zum erolgreichen App-Launch."
        />
        <IconTextRepresentation
          icon={[faCode]}
          title="Entwicklung"
          text="Unsere Designer und Entwickler arbeiten eng miteinander zusammen. Hiermit stellen wir sicher, dass wir ein qualitativ hochwertiges Endergebnis liefern."
        />
        <IconTextRepresentation
          icon={[faRocketLaunch]}
          title="Inbetriebnahme"
          text="Wir kümmern uns um die komplette Abwicklung im App und Google Play Store. Damit steht deiner App nichts mehr im Weg."
        />
        <IconTextRepresentation
          icon={[faCogs]}
          title="App-Support"
          text="Natürlich lassen wir dich nicht im Stich, sobald deine App online ist. Wir kümmern uns um deine App, um sie technisch auf höchstem Level zu halten."
        />
        <IconTextRepresentation
          icon={[faReact]}
          title="Neueste Technologien"
          text="Die Technick entwickelt sich stetig weiter. Um hier schritthalten zu können, nutzen wir die aktuellen Technologien."
        />
      </ImageIconRepresentation>
      <SectionTitle
        category="Projektentwicklung"
        title="Dein App-Projekt bei InterMedia"
      />
      <TimelineTracker
        lineLength={100}
        timeline={[
          {
            title: "1. Konzeption",
            image: Planning,
            text: "Wir versuchen von vorneherein die Weichen zielsicher in die richtige Richtung zu stellen. Damit garantieren wir eine reibungslose Fahrt von der Idee bis hin zur perfekten App.",
          },
          {
            title: "2. Design",
            image: Design,
            text: "Nachdem wir die Weichen für einen reibungslosen Ablauf gestellt haben, beginnen wir damit ein Design für deine App zu entwerfen. Dabei stehen für uns Einzigartigkeit und intuitives Design an oberster Stelle. Natürlich arbeiten auch hier unsere Designer mit den Entwicklern eng zusammen, um im Rahmen des Möglichen zu bleiben. So können Design und Funktion perfekt aufeinander abgestimmt werden. Das Design bildet die Grundlage für unsere Entwickler.",
          },
          {
            title: "3. Programmierung",
            image: Coding,
            text: "Das Warten hat ein Ende und nun können auch endlich unsere Softwareentwickler Gas geben. Die vorher konzipierte Arbeit wird in sogenannte „Sprints“ unterteilt. Das erleichtert es uns dir die laufenden App-Versionen zur Verfügung zu stellen. Dadurch sind wir bei Fehlern und Verbesserungsvorschlägen extrem flexibel und können sofort darauf reagieren. Wir planen den Vorschlag oder die Behebung eines Fehlers einfach im nächsten Sprint ein.",
          },
          {
            title: "4. Release",
            image: Release,
            text: "Sobald die App deinen Ansprüchen und Vorstellungen entspricht, beginnt die Arbeit unserer Produktexperten. Sie prüfen noch einmal die komplette App und nehmen, in enger Zusammenarbeit mit unseren Entwicklern und Designer, letzte Änderungen vor.",
          },
        ]}
      />
      <ContactSection />
      <Footer />
    </>
  );
};
