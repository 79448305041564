import "./index.css";

import App, { AppWrapper } from "./App";

import ReactDOM from "react-dom/client";
import TagManager from "react-gtm-module";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const tagManagerArgs = {
  gtmId: "AW-10964886619",
};

TagManager.initialize(tagManagerArgs);

root.render(<AppWrapper />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
