import "./Header.styles.css";

import React, { useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InterMediaLogoBlack from "../../../../assets/images/logos/LogoBlack.png";
import InterMediaLogoWhite from "../../../../assets/images/logos/LogoWhite.png";
import Layout from "../Layout";
import { Link } from "react-router-dom";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import useScrollPosition from "@react-hook/window-scroll";

interface IHeaderProps {
  background?: "white" | "transparent";
}

export const Header = (props: IHeaderProps) => {
  const { background = "white" } = props;
  const [menuVisible, setMenuVisible] = useState<boolean>(false);
  const [backgroundColor, setBackgroundColor] = useState(background);

  const scrollY = useScrollPosition(60 /*fps*/);

  useEffect(() => {
    if (background === "transparent") {
      if (scrollY >= 100) {
        setBackgroundColor("white");
      }
    }
  }, [scrollY, background]);

  return (
    <>
      <Layout>
        <div
          className={`header ${
            scrollY > 10 && "headerSmall"
          } px-2 lg:px-8 items-center`}
        >
          <div className="mx-auto max-w-screen-xl flex justify-between">
            <div className="ml-5 my-auto">
              <Link to="/">
                <img
                  src={InterMediaLogoBlack}
                  width="200"
                  alt="Das Logo der InterMedia GmbH"
                />
              </Link>
            </div>
            <div className="navigation-items flex flex-row justify-center items-center">
              <Link to="/" className="mx-3">
                <div className="text-black">Home</div>
              </Link>
              <Link to="/Service" className="mx-3">
                <div className="text-black">Service</div>
              </Link>
              <Link to="/Karriere" className="mx-3">
                <div className="text-black">Karriere</div>
              </Link>
              <Link to="/Anfrage" className="mx-3">
                <div className="text-black">Anfrage</div>
              </Link>
              <a
                type="button"
                href="/Anfrage"
                className="ml-3 rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Kostenloses Erstgespräch anfordern
              </a>
            </div>
            <div className={"responsive-navigation"}>
              <div
                className="responsive-menu"
                onClick={() => setMenuVisible(true)}
              >
                <FontAwesomeIcon icon={faBars} />
              </div>
              <div
                className={`responsive-navigation-items ${
                  menuVisible ? "responsive-navigation-items-animation" : ""
                }`}
              >
                <FontAwesomeIcon
                  icon={faTimes}
                  className="dismiss-icon"
                  onClick={() => setMenuVisible(false)}
                />
                <Link to="/" className="responsive-menupoint">
                  Home
                </Link>
                <Link to="/Service" className="responsive-menupoint">
                  Service
                </Link>
                <Link to="/About" className="responsive-menupoint">
                  About
                </Link>
                <Link to="/Karriere" className="responsive-menupoint">
                  Karriere
                </Link>
                <Link to="/Anfrage" className="responsive-menupoint">
                  Anfrage
                </Link>
                <div className="get-in-touch">Get in touch</div>
                <a className="email" href="mailto:hey@inter-media.io">
                  hey@inter-media.io
                </a>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};
