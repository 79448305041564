import React from 'react';
import './Button.styles.css';
interface IButtonProps {
	title: string;
	onPress?(): void;
	variant?: 'small' | 'normal';
}

export const Button = (props: IButtonProps) => {
	const { title, onPress, variant = 'normal' } = props;
	return (
		<button onClick={onPress} className={`ButtonBase ButtonVariant-${variant}`}>
			{title}
		</button>
	);
};
export default Button;
