import './ImageIconRepresentation.style.css';

import React from 'react';
import { useMediaQuery } from 'react-responsive';

import Layout from '../core/Layout';

interface IImageIconRepresentation {
    image: string;
    children: any;
    imageDescription?: string;
    ImageSize?: 'big' | 'small';
    ImageHeight?: number;
    wrapperStyle?: React.CSSProperties;
    containerStyle?: React.CSSProperties;
    wrapOnMobile?: boolean;
}

export const ImageIconRepresentation = (props: IImageIconRepresentation) => {
    const {
        image,
        children,
        ImageSize = 'small',
        containerStyle,
        wrapperStyle,
        imageDescription,
        wrapOnMobile = false,
    } = props;

    const isMobile = useMediaQuery({ query: '(max-width: 650px)' });

    return (
        <div className={'imageIconMainWrapper'} style={{ ...wrapperStyle }}>
            <Layout style={{ display: 'flex', position: 'relative', paddingLeft: 0 }}>
                <img src={image} className="image" alt={imageDescription}></img>
                <div
                    className="imageIconContainer"
                    style={{
                        flexDirection: ImageSize === 'small' ? 'row' : 'column',
                        ...containerStyle,
                    }}
                >
                    {children}
                </div>
            </Layout>
        </div>
    );
};
