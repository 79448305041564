import { EntranceScreen } from "../../components/core/EntranceScreen/EntranceScreen";
import Footer from "../../components/core/Footer";
import { Header } from "../../components/core/Layout/Header/Header";
import { Helmet } from "react-helmet";
import { InlineWidget } from "react-calendly";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export const Anfrage = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <Helmet>
        <title>Anfrage</title>
        <meta name="description" content="Erzähl uns von deinem Projekt" />
      </Helmet>
      <Header />
      <EntranceScreen
        title="Kostenloses Erstgespräch"
        subtitle="Vereinbaren Sie Ihr kostenloses Erstgespräch mit einem unserer Strategieberater!"
        altText="Ein App-Entwickler beim Testen einer App auf dem Smartphone"
      />
      <InlineWidget
        pageSettings={{ hideGdprBanner: true }}
        url="https://calendly.com/meetintermedia/gespraech"
        styles={{ height: "1000px" }}
      />
      <Footer />
    </>
  );
};

export default Anfrage;
