interface ICookieConsentProps {
  onAccept(): void;
  onReject(): void;
}

export const CookieConsent = (props: ICookieConsentProps) => {
  const { onAccept, onReject } = props;
  return (
    <div className="pointer-events-none fixed inset-x-0 bottom-0 px-6 pb-6 z-50">
      <div className="pointer-events-auto mx-auto max-w-xl rounded-xl bg-white p-6 shadow-lg ring-1 ring-gray-900/10">
        <p className="text-sm leading-6 text-gray-900">
          Wir nutzen Cookies und Pixel um Dir die bestmögliche
          Browsing-Erfahrung zu bieten. Die mit Hilfe von Cookies und Pixeln
          gesammelten Daten werden zur Optimierung unserer Webseite genutzt und
          um Nutzern und potenziellen Neukunden die für sie relevantesten
          Informationen anzuzeigen. Mehr Informationen findest du in unseren{" "}
          <a href="/Datenschutz" className="font-semibold text-indigo-600">
            Datenschutzbestimmungen
          </a>
          .
        </p>
        <div className="mt-4 flex items-center gap-x-5">
          <button
            type="button"
            onClick={onAccept}
            className="rounded-md bg-gray-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
          >
            Akzeptieren
          </button>
          <button
            onClick={onReject}
            type="button"
            className="text-sm font-semibold leading-6 text-gray-900"
          >
            Ablehnen
          </button>
        </div>
      </div>
    </div>
  );
};
