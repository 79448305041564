import React from 'react';

import { Button } from '../core/Button/Button';

interface IJobCardProps {
    jobTitle: string;
    jobCategory: string;
    description: string;
    link?: string;
}

export const JobCard = (props: IJobCardProps) => {
    const { jobTitle, jobCategory, description } = props;
    return (
        <>
            <div className="jobCardRoot" style={{ width: '25%', border: '1px solid #979797', padding: 20 }}>
                <div style={{ color: '#3741DF' }}>{jobTitle}</div>
                <div style={{ color: 'rgba(0,0,0,0.63)' }}>{jobCategory}</div>
                <div
                    style={{
                        color: 'rgba(0,0,0,0.63)',
                        marginTop: 15,
                        height: 110,
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                    }}
                >
                    {description}
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', flex: 1, marginTop: 16 }}>
                    <a href="mailto:hey@inter-media.io">
                        <Button title="Jetzt Bewerben" variant="small" />
                    </a>
                </div>
            </div>
        </>
    );
};
