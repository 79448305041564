import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";

interface IBenefitProps {
  title: string;
}

export const Benefit = (props: IBenefitProps) => {
  const { title } = props;
  return (
    <div className="flex flex-row items-center my-3">
      <FontAwesomeIcon icon={faCheckCircle} className="mr-3 text-green-600" />
      <div className="font-bold">{title}</div>
    </div>
  );
};
