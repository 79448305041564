import React from 'react';
import './Layout.styles.css';

interface ILayoutProps {
    children: React.ReactElement | React.ReactElement[];
    marginLeft?: number;
    marginRight?: number;
    style?: React.CSSProperties;
}

export const Layout = (props: ILayoutProps) => {
    const { children, marginLeft, marginRight, style } = props;
    return (
        <>
            <div className="layout" style={{ paddingLeft: marginLeft, paddingRight: marginRight, ...style }}>
                {children}
            </div>
        </>
    );
};

export default Layout;
