import "./Footer.style.css";

import {
  faFacebookF,
  faInstagram,
  faLinkedinIn,
} from "@fortawesome/free-brands-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InterMediaLogoWhite from "../../../assets/images/logos/LogoWhite.png";
import Layout from "../Layout";
import { Link } from "react-router-dom";
import React from "react";

export const Footer = () => {
  return (
    <div className="footer">
      <Layout>
        <>
          <div className="footerInner mx-auto max-w-screen-xl">
            <div className="footerLogo">
              <div className="footerLogo1">
                <img
                  src={InterMediaLogoWhite}
                  style={{ height: 50 }}
                  alt="Das Logo der InterMedia GmbH"
                />
              </div>
              <div className="footerLogoSocial">
                <div className="box">
                  <a
                    href="https://www.linkedin.com/company/74217743"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FontAwesomeIcon icon={faLinkedinIn} color="white" />
                  </a>
                </div>
                <div className="box">
                  <a
                    href="https://www.instagram.com/intermediads/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FontAwesomeIcon icon={faInstagram} color="white" />
                  </a>
                </div>
                <div className="box">
                  <a
                    href="https://www.facebook.com/InterMediaDigitalSolutions"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FontAwesomeIcon icon={faFacebookF} color="white" />
                  </a>
                </div>
              </div>
            </div>
            <div className="footerInfos">
              <div className="footerInfosItem">
                <div className="footerInfosTitle">Rechtliches</div>
                <div>
                  <Link to="/impressum">Impressum</Link>
                </div>
                {/* <div>
                                    <Link to="/AGB">AGB</Link>
                                </div> */}
                <div>
                  <Link to="/Datenschutz">Datenschutz</Link>
                </div>
              </div>
              <div className="footerInfosItem">
                <div className="footerInfosTitle">Unternehmen</div>
                <div>
                  <Link to="/About">About</Link>
                </div>
                <div>
                  <Link to="/Karriere">Karriere</Link>
                </div>
                <div></div>
              </div>
              <div className="footerInfosItem">
                <div className="footerInfosTitle">Kontakt</div>
                <div>
                  <Link to="/Anfrage">Anfrage</Link>
                </div>
                <div>
                  <a href="mailto:hey@inter-media.io">E-Mail</a>
                </div>
              </div>
              <div className="footerInfosItem">
                <div className="footerInfosTitle">Services</div>
                <div>
                  <Link to="/Service">App-Development</Link>
                </div>
                {/* <div>
                                    <Link to="/Service">Background-Services</Link>
                                </div> */}
              </div>
            </div>
          </div>
          <div className="copyright">&copy; 2023 InterMedia GmbH</div>
        </>
      </Layout>
    </div>
  );
};

export default Footer;
