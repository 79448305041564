import React from 'react';

interface ISmallTitleProps {
	title: string;
	subtitle: string;
}

export const SmallTitle = (props: ISmallTitleProps) => {
	const { title, subtitle } = props;
	return (
		<div className='smallTitle' style={{ marginTop: 100, marginBottom: 30 }}>
			<div style={{ fontSize: '30px', textAlign: 'center' }}>{title}</div>
			<div
				style={{
					textAlign: 'center',
					marginTop: 18,
					maxWidth: '45%',
					marginLeft: 'auto',
					marginRight: 'auto',
				}}
			>
				{subtitle}
			</div>
		</div>
	);
};

export default SmallTitle;
