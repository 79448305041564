import "./App.css";

import { BrowserRouter, Route, Switch } from "react-router-dom";
import { CookiesProvider, useCookies } from "react-cookie";

import Anfrage from "./pages/Anfrage/Anfrage";
import { AppDevelopment } from "./pages/AppDevelopment/AppDevelopment";
import { BackgroundServices } from "./pages/BackgroundServices/BackgroundServices";
import { CookieConsent } from "./components/CookieConsent/CookieConsent";
import Datenschutz from "./pages/Datenschutz/Datenschutz";
import { Error404 } from "./pages/404";
import { GTMProvider } from "@elgorditosalsero/react-gtm-hook";
import { Home } from "./pages/Home/Home";
import Impressum from "./pages/Impressum/Impressum";
import { Karriere } from "./pages/Karriere/Karriere";

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route exact path="/Karriere">
          <Karriere />
        </Route>
        <Route exact path="/Impressum">
          <Impressum />
        </Route>
        <Route exact path="/Datenschutz">
          <Datenschutz />
        </Route>
        <Route exact path="/Service">
          <AppDevelopment />
        </Route>
        <Route exact path="/Background-Services">
          <BackgroundServices />
        </Route>
        <Route exact path="/Anfrage">
          <Anfrage />
        </Route>
        <Route path="*">
          <Error404 />
        </Route>
      </Switch>
    </BrowserRouter>
  );
}

export const AppWrapper = () => {
  const gtmParams = { id: "GTM-MNPJD34" };

  const [cookies, setCookie] = useCookies(["consent"]);

  return (
    <GTMProvider state={gtmParams}>
      <CookiesProvider>
        <App />
        {!cookies.consent && (
          <CookieConsent
            onAccept={() => setCookie("consent", true)}
            onReject={() => setCookie("consent", false)}
          />
        )}
      </CookiesProvider>
    </GTMProvider>
  );
};

export default App;
