import "./Error404.style.css";

import Button from "../components/core/Button";
import { Header } from "../components/core/Layout/Header/Header";
import { Helmet } from "react-helmet";
import IMGError from "../assets/images/Other/Error.svg";
import { Link } from "react-router-dom";
import React from "react";

export const Error404 = () => {
  return (
    <>
      <Helmet>
        <title>404 - Seite nicht gefunden</title>
        <meta
          name="description"
          content="Die Seite nach der du suchst, wurde nicht gefunden"
        />
      </Helmet>
      <Header />
      <div className="errorPageWrapper">
        <div className="errorInformationContainer">
          <div className="errorImageContainer">
            <img
              src={IMGError}
              width={400}
              height={450}
              alt="Illustration 404 Seite nicht gefunden"
              className="errorImage"
            />
          </div>
          <div className="errorTextContainer">
            <div className="textTitle bigTitle">404</div>
            <div className="textTitle">
              Die Seite nach der du suchst, wurde nicht gefunden!
            </div>
            <Link to="/" className="backHomeButton">
              <Button title="Zurück zur Startseite" />
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};
