import "./TimelineTracker.style.css";

import ImageLine from "../../assets/images/Other/Line.png";
import Layout from "../core/Layout";
import React from "react";
import { useMediaQuery } from "react-responsive";

export interface ITimeline {
  image: string;
  title: string;
  text: string;
}

interface ITimelineTrackerProps {
  timeline: ITimeline[];
  lineLength?: number;
}

export const TimelineTracker = (props: ITimelineTrackerProps) => {
  const { timeline, lineLength = 100 } = props;

  const ImageWidth = 250;

  const isMobile = useMediaQuery({ query: "(max-width: 650px)" });

  return (
    <Layout>
      <div
        style={{
          marginTop: 100,
          marginBottom: 100,
          marginLeft: "3%",
          marginRight: "3%",
        }}
      >
        {timeline.map((singleTimeline: ITimeline, index) => (
          <div key={index}>
            <div className="timelineRoot">
              <div className="flex items-center content-center">
                <img
                  src={singleTimeline.image}
                  style={{ width: isMobile ? 200 : ImageWidth }}
                  alt={"alt"}
                />
              </div>
              <div className="textRoot">
                <div
                  style={{
                    color: "#525FEA",
                  }}
                >
                  {singleTimeline.title}
                </div>
                <div>{singleTimeline.text}</div>
              </div>
            </div>
            {index < timeline.length - 1 && (
              <div
                style={{
                  height: lineLength,
                  marginLeft: isMobile ? 0 : ImageWidth / 2,
                }}
                className="timelineSpacerLine"
              >
                <img
                  src={ImageLine}
                  style={{ height: lineLength }}
                  alt="Line"
                />
              </div>
            )}
          </div>
        ))}
      </div>
    </Layout>
  );
};

export default TimelineTracker;
