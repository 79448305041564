import './IconTextRepresentation.style.css';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

interface IIconTextRepresentation {
    icon: IconProp[];
    title: string;
    text: string;
    fullwidth?: boolean;
}

export const IconTextRepresentation = (props: IIconTextRepresentation) => {
    const { icon, title, text, fullwidth = false } = props;
    return (
        <div className="iconTextRoot" style={{ width: fullwidth ? '100%' : 'calc(50% - 50px)' }}>
            <div className="iconTextIcon">
                {icon.map((icon: IconProp, index) => (
                    <FontAwesomeIcon
                        key={index}
                        icon={icon}
                        size={'3x'}
                        color="#262CCF"
                        style={{ marginLeft: index > 0 ? '20px' : '0px' }}
                    />
                ))}
            </div>
            <div className="iconTextTitle">{title}</div>
            <div className="iconTextDescription">{text}</div>
        </div>
    );
};
export default IconTextRepresentation;
